
    import Vue , { defineComponent , PropType} from "vue";
    import { ImageField } from '@/models/QuizModel';
    export default defineComponent({
        name: 'ImageArea',
        props: {
            field: {
                type: Object  as PropType<ImageField>,
                required: false,
                default: null,
            },
        },
    });


import { defineComponent , PropType } from "vue";
import { ClickAreaModel } from '@/models/QuizModel';
import TextArea from '@/components/TextArea.vue';
import ImageArea from '@/components/ImageArea.vue';
import AudioArea from '@/components/AudioArea.vue';
export default defineComponent({
  name: 'ClickArea',
  components: {
      TextArea,
      ImageArea,
      AudioArea,
  },
  props: {
      clickAreaModel : {
          type: Object  as PropType<ClickAreaModel>,
          required: false,
          default: null,
      },
      currentIndex : {
          type: Number ,
          defualt: true,
      },
      beClicked : {
          type: Boolean,
          defualt: false,
      },
      isBlankFill : {
          type: Boolean,
          required: false,
          default: null,
      },
      labelIndex : {
          type: Number,
          required: false,
          default: null,
      },

      questionNumber : {
          type: String,
          required: false,
      },
      IsCorrect : {
          type: Number,
          default: null,
      },
      clickAreaIndex:{
          type: Number,
          default: null,
      },
  },
  data() {
    return {
        localClickAreaModel : {} as ClickAreaModel ,
    };
  },
  watch: {
    clickAreaModel : function() {
        this.localClickAreaModel = this.clickAreaModel
    },
    currentIndex : function() {
        console.log("hi")
    },
  },
  computed: {

  },
  methods: {
      checkBlockExist(){
          if( this.localClickAreaModel.content.Audio.enabled ){
              return true;
          }
          else if( this.localClickAreaModel.content.textField.enabled && this.localClickAreaModel.content.textField.text!="" && this.localClickAreaModel.label=="Title"  && this.localClickAreaModel.content.textField.text!='\n'){
              return true;
          }
          else if( this.localClickAreaModel.content.textField.enabled && this.localClickAreaModel.label!="Title"){
              return true;
          }
          else if( this.localClickAreaModel.content.imageField.enabled ){
              return true;
          }
          return false;
      },
      getTextField(){
      }
  },
  created() {
      this.localClickAreaModel = this.clickAreaModel;
  }
});


    import Vue , { defineComponent , PropType} from "vue";
    import { TextField } from '@/models/QuizModel';

    export default defineComponent({
        name: 'TextArea',
        props: {
            field: {
                type: Object  as PropType<TextField>,
                required: false,
                default: null,
            },
            label: {
                type: String,
                required: false,
                default: null,
            },
            isBlankFill : {
                type: Boolean,
                required: false,
                default: null,
            },
            labelIndex : {
                type: Number,
                required: false,
                default: null,
            },
            questionNumber:{
                type: String,
                default: null,
            },
            AudioEnable:{
                type: Boolean,
                default: null,
            },
            clickAreaIndex:{
                type: Number,
                default: null,
            },
        },
        data() {
            return {
            };
        },
        methods: {
            getText(){
                console.log("this.field.text: " + this.field.text)
                if(this.field.text == '#')  return '';
                else  return this.field.text.replace(/\n/g, '<br>');
            },
            getLabel(){
                let labels = [ "A" , "B" , "C" , "D" , "E"]
                let text = '';
                if(this.field.text == '#')  text = '';
                else  text = this.field.text.replace(/\n/g, '<br>');
                if(!this.isBlankFill){
                    if( this.label != "Title" ){
                        return "( " + labels[this.labelIndex] + " ) " + text;
                    }
                    else if(this.field.text!="" || (( this.label == "Title" ) && this.AudioEnable)){
                        if(this.questionNumber!=""){//not description
                            if(this.clickAreaIndex==0 && this.label=="Title" && this.questionNumber.includes('.')){
                                let number = this.questionNumber.split('.');
                                return number[0] + ". "  + text;
                            }
                            return this.questionNumber + ". " + text;
                        }
                    }
                }
                else if(this.questionNumber!=""){
                    if(this.clickAreaIndex==0 && this.label=="Title" && this.questionNumber.includes('.')){
                        let number = this.questionNumber.split('.');
                        return number[0] + text;
                    }
                    else  return this.questionNumber + ". " + text;
                }
                return text;
            },
        },
    });


  import Vue from 'vue';
  import { Answer } from '@/models/AnswerModel';
  import { ClickAreaModel } from '@/models/QuizModel';
  import AnswerGroupApi from '@/apis/AnswerGroupApi';
  import ClickAreaForShowResult from '@/components/ClickAreaForShowResult.vue';
  import { AnswerGroupResultRespondModel } from '@/models/AnswerGroupResultModel';
  // import isEqual from 'lodash.isequal';
  import _ from 'lodash';

  export default Vue.extend({
    name: 'AnswerGroupResult',
    components: {
      ClickAreaForShowResult,
    },
    props: {
    },
    data() {
      return {
        currentAnswerModel: {} as Answer,
        answerModelList: new Array<Answer>(),
        currentIndex: 0,
        answerResult: {} as AnswerGroupResultRespondModel,
        originalQuizInstanceName: '',
        modalShow: false,
        quizNumber: '1',
      };
    },
    computed: {
    },
    async created() {
      this.$store.commit('updateLoading', true);
      try {
        const answerResultInstance = JSON.parse(JSON.stringify(await AnswerGroupApi.getQuizGroupById(this.$store.state.currentQuizWorkspace.uuid)));

        this.answerResult = new AnswerGroupResultRespondModel(
                            answerResultInstance.detail.uuid,
                            answerResultInstance.detail.totalScore,
                            answerResultInstance.detail.sourceQuizGroupId,
                            answerResultInstance.detail.sourceQuizGroupSize,
                            answerResultInstance.detail.answerList,
        );
        const answerViewInstance = answerResultInstance.result;

        console.log('answer');
        console.log(this.answerResult.answerList);
        const newAnswerList = this.answerResult.answerList as Array<any>;
        const newAnswerModelList = new Array<Answer>();
        for (let i = 0; i < this.answerResult.answerList.length; i += 1) {
          const uuid2 = newAnswerList[i].uuid;
          const questionNumber2 = newAnswerList[i].questionNumber;
          const {
            multipleSelect, isBlankFill, blankFillAnswer,
          } = newAnswerList[i];
            // retrieve clickArea from answerViewInstance
          for (let j = 0; j < answerViewInstance.answerList.length; j += 1) {
            if (answerViewInstance.answerList[j].sourceQuiz.uuid === newAnswerList[i].sourceQuiz.uuid) {
              const {
                sourceQuiz,
              } = answerViewInstance.answerList[j];
              const answer = new Answer(sourceQuiz, uuid2, multipleSelect, isBlankFill, blankFillAnswer, sourceQuiz.score, questionNumber2, newAnswerList[i].sourceQuiz.solution);
              answer.setRealAnswer(newAnswerList[i].sourceQuiz.answerSet);
              if (answer.isBlankFill) {
                answer.setRealBlankFillAnswer(answerViewInstance.answerList[j].realBlankFillAnswer);
              }
              newAnswerModelList.push(answer);
            }
          }
        }
        console.log(newAnswerModelList);
        this.answerModelList = newAnswerModelList;
        this.currentAnswerModel = newAnswerModelList[0];
      } finally {
        // this.$emit('getQuizGroupSize',this.answerResult.sourceQuizGroupSize)
        // this.$emit('getTotalScore',this.answerResult.totalScore)
        this.$store.commit('updateLoading', false);
      }
    },
    methods: {
      isQuestion(number: string) {
        if (number !== '') {
          return number;
        }

        return '#';
      },
      openModel() {
        this.modalShow = true;
      },
      changeNum(quizNum: string, changeIndex: number) {
        this.currentIndex = changeIndex;
        if (quizNum === '#') {
          this.quizNumber = '';
        } else {
          this.quizNumber = quizNum;
        }
        console.log(`quizNumber${this.quizNumber}`);
        // this.currentIndex = this.currentAnswerModel.questionNumber
        this.currentAnswerModel = this.answerModelList[changeIndex];
      },
      // checkThisAnswerIsCorrect(clickArea: ClickAreaModel) {
      //   if (this.currentAnswerModel.isBlankFill) {
      //     return false;
      //   }
      //   const { realAnswer } = this.currentAnswerModel;
      //   if (realAnswer.includes(clickArea.label)) {
      //     return true;
      //   }
      //   return false;
      // },
      checkThisLabelIsInCorrect(label: string) {
        // 0:沒被選中也不是答案或是不是選擇題、1:被選中而且是答案、2:被選中但不是答案
        if (this.currentAnswerModel.isBlankFill) {
          return 0;
        }

        const { realAnswer } = this.currentAnswerModel; // 當前選項的label
        if (realAnswer.includes(label)) return 1; // 正確答案

        return 0;
      },
      getAreaIndex(label: string) {
        if (label !== 'Title') {
          let index = 0;
          for( let i = 0 ; i < this.currentAnswerModel.clickAreas.length ; i++){
            if (this.currentAnswerModel.clickAreas[i].label !== 'Title') {
              if (this.checkBlockExist(this.currentAnswerModel.clickAreas[i])) {
                index += 1;
              }
            }
            if (this.currentAnswerModel.clickAreas[i].label == label) {
              return index - 1;
            }
          }
        }
        return -1;
      },
      checkBlockExist(clickArea: ClickAreaModel) {
        if (clickArea.content.Audio.enabled) {
          return true;
        } if (clickArea.content.textField.enabled && clickArea.content.textField.text !== '') {
          return true;
        } if (clickArea.content.imageField.enabled) {
          return true;
        }
        return false;
      },
      getRealAnswer() {
        let realAnswer = '';
        if (this.currentAnswerModel.isBlankFill) {
          if (this.currentAnswerModel.realBlankFillAnswer !== undefined) {
            const keys = ['A', 'B', 'C', 'D', 'E'];

            for (let i = 0; i < keys.length; i += 1) {
              const tempAnswer = this.currentAnswerModel.realBlankFillAnswer[keys[i]];
              if (tempAnswer !== '') {
                if (tempAnswer !== undefined) {
                  if (keys[i] !== 'A') {
                    realAnswer = `${realAnswer} / `;
                  }
                  realAnswer += tempAnswer;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.currentAnswerModel.realAnswer.length; i += 1) {
            realAnswer = `${realAnswer} ${this.currentAnswerModel.realAnswer[i]}`;
          }
        }
        return realAnswer;
      },
    },
  });

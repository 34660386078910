export interface AnswerGroupResultModel {
    uuid: string;
    totalScore: number;
    sourceQuizGroupId: string;
    sourceQuizGroupSize: number;
    answerList: Array<unknown>;
}

export class AnswerGroupResultRespondModel implements AnswerGroupResultModel {
    uuid: string;
    totalScore: number;
    sourceQuizGroupId: string;
    sourceQuizGroupSize: number;
    answerList: Array<unknown>;
    constructor( uuid: string, totalScore: number, sourceQuizGroupId: string, sourceQuizGroupSize: number, answerList: Array<unknown>) {
        this.uuid = uuid;
        this.totalScore = totalScore;
        this.sourceQuizGroupId = sourceQuizGroupId;
        this.sourceQuizGroupSize = sourceQuizGroupSize;
        this.answerList = answerList;
    }
}
